import _ from 'lodash';
import { fusionConfigurationPromotionMock, mockSystemFiles } from '../../components/fusion-configuration-promotion/fusion-configuration-promotion-mock';
import { FusionPromotionFile, FusionPromotionRequest, FusionPromotionResponse } from '../../components/fusion-configuration-promotion/interfaces';
import { CCApiService } from './api-service';
import { UIConfig } from "./interfaces";

export class FusionConfigurationPromotionService {
  static ApiService: CCApiService;
  static fusionPromotionApi: string;
  static integratedSystemApi: string;
  static apiBasePath: string;
  static customerId: string;
  static mockResults: any;
  static instanceFiles: any;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.customerId = customerId;
    this.fusionPromotionApi = `${config.apiBasePath}/customers/${customerId}/configuration-promotions`;
    this.integratedSystemApi = `${config.apiBasePath}/customers/${customerId}/integrated-systems`;
    this.mockResults = fusionConfigurationPromotionMock();
    this.instanceFiles = mockSystemFiles;
  }

  static async getPromotions(filter: {}, mockMode = false): Promise<any> {
    if(mockMode) {
      return Promise.resolve(fusionConfigurationPromotionMock(filter));
    }
    return this.ApiService.performFetch(`${this.fusionPromotionApi}?${new URLSearchParams(filter)}`).then(result => result);
  }

  static async getPromotion(promotionId: string, mockMode = false): Promise<FusionPromotionResponse> {
    if(mockMode) {
      return Promise.resolve(_.find(this.mockResults.content, { id: promotionId }));
    }
    return this.ApiService.performFetch(`${this.fusionPromotionApi}/${promotionId}`).then(result => result);
  }

  static async createOrUpdatePromotion(promotionId: string, options: FusionPromotionRequest, mockMode = false): Promise<FusionPromotionResponse> {
    const fetchOptions = {
      method: promotionId ? 'PUT' : 'POST',
      body: JSON.stringify(options),
    };
    if(mockMode) {
      const id = promotionId || Math.random().toString(36).substring(7);
      _.set(this.mockResults, id, options);
      return Promise.resolve(_.find(this.mockResults, { id }));
    }
    const promotionIdPath = promotionId ? `/${promotionId}` : '';
    return this.ApiService.performFetch(`${this.fusionPromotionApi}${promotionIdPath}`, fetchOptions).then(result => result);
  }

  static async deletePromotion(promotionId: string, mockMode = false): Promise<void> {
    const fetchOptions = {
      method: 'DELETE',
    };
    if(mockMode) {
      _.remove(this.mockResults, { id: promotionId });
      return Promise.resolve();
    }
    return this.ApiService.performFetch(`${this.fusionPromotionApi}/${promotionId}`, fetchOptions).then(result => result);
  }

  static async withdrawPromotion(promotionId: string, mockMode = false): Promise<void> {
    const fetchOptions = {
      method: 'PUT',
    };
    if(mockMode) {
      _.remove(this.mockResults, { id: promotionId });
      return Promise.resolve();
    }
    return this.ApiService.performFetch(`${this.fusionPromotionApi}/${promotionId}/withdraw`, fetchOptions).then(result => result);
  }

  static async getFilesList(systemId: string, mockMode = false): Promise<FusionPromotionFile[]> {
    if(mockMode) {
      if(systemId === "fbc52d23-5ff5-4f5b-a3e9-57b440247a93") {
        return Promise.resolve(this.instanceFiles[systemId]);
      }
      return Promise.resolve(this.instanceFiles["others"]);
    }
    return this.ApiService.performFetch(`${this.integratedSystemApi}/${systemId}/repository/branch`).then(result => result);
  }
}
